import React, {Component} from "react";
import Request from "superagent";
import "./styles/styles.scss";
import request from "superagent";
import NumberFormat from "react-number-format";

const host = process.env.NODE_ENV === "production" ? "https://igateapi.herokuapp.com" : "127.0.0.1:8081";

class App extends Component {
	constructor(props) {
		super(props);
		this.nombreInputRef = React.createRef();
		this.apellidosInputRef = React.createRef();
		this.state = {
			failed: false,
			success: false,
			message: "",
			backLink: localStorage.getItem("backLink") || "appmosphera://",
			saldo: 0,
			API_KEY: "",
			isSandbox: true,
			nombre:"",
			apellidos:"",
			borderColor_nombre:"rgb(206, 206, 206)",
			borderColor_apellidos:"rgb(206, 206, 206)",
			showUserInfo:false

		};
	}

	componentDidMount() {
		//this.loadToken()
		const url_string = window.location.href;
		const url = new URL(url_string);
		const searchParams = new URLSearchParams(url.search);
		const failed = searchParams.get("failed");
		const success = searchParams.get("success");
		const message = searchParams.get("message");
		const backLink = searchParams.get("back");
		const token = searchParams.get("token");
		if (token) {
			this.loadSaldo(token);
			this.setState({showUserInfo:true})
		}

		if (backLink) {
			localStorage.setItem("backLink", backLink);
		}

		this.setStatus(failed, success, message, backLink);
	}

	setStatus(failed, success, message) {
		this.setState({failed, success, message});
	}

	//solo para pruebas
	async loadToken() {

		console.log(`127.0.0.1:8081/netpay/makeToken`);
		
		const user = {
			idFraccionamiento: 19,
			idInquilino: 395,
			idPersona: 449,
			idVivienda:80,
			nombre:"Dayron Pedroso",
			saldo:70000,
			telefono:"+5359170315"

		};

		 const response = await Request.post(`${host}/netpay/makeToken`,{saldo:63000,...user})
		 .set("Accept", "application/json")
			.set("Content-Type", "application/json")
			.set("igate_key", "5fcae9a7be1dfa53da44c353492bc73d")
			.then((res) => {
				if (res.body) {
					 console.log(res.body);
					return res.body;
				} else {
					return {error: true, message: "Internal Server Error"};
				}
			})
			.catch((error) => {
				return {error: true, message: error.message};
			});


	}

	async loadSaldo(token) {
		this.setState({loading: true});
		console.log(`${host}/netpay/saldo`);
		const res = await Request.post(`${host}/netpay/saldo`, {token})
			.set("Accept", "application/json")
			.set("Content-Type", "application/json")
			.set("igate_key", "5fcae9a7be1dfa53da44c353492bc73d")
			.then((res) => {
				if (res.body) {
					// console.log(res.body);
					return res.body;
				} else {
					return {error: true, message: "Internal Server Error"};
				}
			})
			.catch((error) => {
				return {error: true, message: error.message};
			});

		if (res.error) {
			this.setState({error: true, message: res.message || "Error interno.", loading: false});
			return;
		}

		if (res.saldo) {
			const nombre = res.nombre.split(' ');
			this.setState({saldo: res.saldo || 0.0, id_vivienda: res.idVivienda, id_inquilino: res.idInquilino,
			nombre:nombre[0],apellidos:nombre[1]});
		}

		if (res.API_KEY) {
			if (!this.state.success && !this.state.failed) {
				//alert(res.API_KEY);
				//console.log(res);
				window.NetPay.setApiKey(res.API_KEY);
				window.NetPay.setSandboxMode(res.isSandbox);
				window.NetPay.form.generate("netpay-form", this.onSuccess.bind(this), this.onError.bind(this));
			}
		}

		this.setState({loading: false});
	}

	async onError(event) {
		if (event.message) {
			alert(event.message);
			//console.log(event.message);
		} else {
			alert("Error al generar el tokén.");
		}
		//console.log(event);
	}

	async onSuccess(event) {
		let {message} = event;
		if (message) {
			if(this.state.nombre && this.state.apellidos){
				const pay = JSON.parse(message.data);
			const data = {
				pay,
				saldo: this.state.saldo,
				vivienda: this.state.id_vivienda,
				inquilino: this.state.id_inquilino,
				usuario:{
					nombre:this.state.nombre,
					apellidos:this.state.apellidos
				}
			};
			console.log(data);
			console.log(`${host}/netpay/checkout`);
			const res = await Request.post(`${host}/netpay/checkout`, data)
				.set("Accept", "application/json")
				.set("Content-Type", "application/json")
				.set("igate_key", "5fcae9a7be1dfa53da44c353492bc73d")
				.then((res) => {
					if (res.body) {
						// console.log(res.body);
						const {throwError, message} = res.body;
						if (throwError) {
							this.setState({error: true, message});
							return false;
						} else {
							const response = res.body;
							if (response.status == "failed") {
								window.location = `/?failed=true&message=${response.error}`;
							} else if (response.status == "success") {
								window.location = `/?success=true&message=${response.message}`;
							} else if (response.status === "review") {
								// console.log(response);
								window.location = response.returnUrl;
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					//alert(error.message || 'Internal Server Error');
					window.location.reload();
					return false;
				});

			return res;
			}
			else{
				if(this.state.nombre)this.nombreInputRef.current.style.borderColor = "red"
				if(this.state.apellidos)this.apellidosInputRef.current.style.borderColor="red"
				alert('Datos de usuario imcompletos')
				window.location.reload();
				return false;
			}
			
		} else {
			//console.log('Algo salió mal.');
			return false;
		}
	}


	render() {
		if (this.state.failed) {
			return (
				<div className="failed">
					<div className="container">
						<div className="icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
								<line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
								<line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
							</svg>
						</div>
						<div className="title">
							<h1>No se pudo completar el pago</h1>
						</div>
						<p>{this.state.message}</p>
						<div className="back-appmosphera">
							<a href={this.state.backLink}>Volver a AppMosphera</a>
						</div>
					</div>
				</div>
			);
		} else if (this.state.success) {
			return (
				<div className="success">
					<div className="container">
						<div className="icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle className="path circle" fill="none" stroke="#439ca0" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
								<polyline className="path check" fill="none" stroke="#439ca0" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
							</svg>
						</div>
					</div>
					<div className="title">
						<h1>Pago completado</h1>
					</div>
					<p>{this.state.message}</p>
					<div className="back-appmosphera">
						<a href={this.state.backLink}>Volver a AppMosphera</a>
					</div>
				</div>
			);
		} else if (this.state.error) {
			return (
				<div className="failed">
					<div className="container">
						<div className="icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
								<circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
								<line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
								<line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
							</svg>
						</div>
					</div>
					<div className="title">
						<h1>No se pudo realizar el pago.</h1>
					</div>
					<p>{this.state.message}</p>
					<div className="back-appmosphera">
						<a href={this.state.backLink}>Volver a AppMosphera</a>
					</div>
				</div>
			);
		} else {
			return (
				<div className="form">
					<div className="container" style={{marginBottom:"20px"}}>
						<div className="pay-info">
							<h2>
								Por pagar: &nbsp;
								<NumberFormat displayType={"text"} thousandSeparator={true} decimalScale={2} prefix={"$"} value={this.state.saldo.toFixed(2)} />
							</h2>
						</div>
						{this.state.showUserInfo ? (
							<div className="user-info" style={{marginBottom:"20px"}}>
							<form>
								<h1>Datos del usuario</h1>
								<label>	<label style={{color:"red"}}>*</label>Nombre:</label>								
								<input  ref={this.nombreInputRef} type="text" style={{borderColor:this.state.borderColor_nombre}} value={this.state.nombre}
								onChange={event =>{
									event.target.value===''?this.setState({ borderColor_nombre: "red" }):this.setState({ borderColor_nombre: "rgb(206, 206, 206)" })
									this.setState({ nombre: event.target.value });
								} }>
								</input>
								<label><label style={{color:"red"}}>*</label>Apellidos:</label>
								<input ref={this.apellidosInputRef} type="text" style={{borderColor:this.state.borderColor_apellidos}} value={this.state.apellidos}
	                            onChange={event => {
									event.target.value===''?this.setState({ borderColor_apellidos: "red" }):this.setState({ borderColor_apellidos: "rgb(206, 206, 206)" })
									this.setState({ apellidos: event.target.value })
								}							
									}>
								</input>
							</form>
						</div>
						) : null}
						
						{this.state.loading ? (
							<div className="loading">
								<i className="fas fa-spinner fa-spin"></i>
							</div>
						) : null}
						<div id="netpay-form"></div>
					</div>
				</div>
			);
		}
	}
}

export default App;
